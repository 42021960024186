
html, body {
	width: 100%;
	height: 100%;
}

.home-main {
	min-height: 95vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;
}

.home-header {

}

.home-body {
    width: 100%;
    font-size: 110%;
}


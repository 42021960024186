
html, body {
	width: 100%;
	height: 100%;
}

.lb-main {
	min-height: 100vh;
	display: grid;
	grid-template-rows: auto 1fr auto;
	grid-template-columns: 100%;
}

.lb-body {
    width: 100%;
    font-size: 110%;
}

.lb-hrzn-header {
	margin-top: 2px;
	margin-bottom: 2px;
	background-color: gray-600;
	font-size: larger;
}


.lb-table-bull {
	background-color: yellowgreen;
}

.lb-table-bear {
	background-color: tomato;
}


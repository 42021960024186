/* footer div style */

.footer-main {
	bottom: 0;
	width: 100%;
	height: 150px;
}


.in-logo {
    height: 30px;
    width: 30px;
    margin-left: 5px;
}

.insta-logo {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.pin-logo {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

.twit-logo {
    height: 30px;
    width: 30px;
    margin-left: 10px;
}

